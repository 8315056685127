export default {
  API_ROOT: "api.treasureisland.gtpdev.com",
  TITLE: "TI Dev",
  FACEBOOK_PIXEL_BASE: undefined,
  XTREMEPUSH_ID: "hqt_kd6n2Gq6QRiX_QIRQLqj9iVZvQCB",
  APPLICATION_TARGET: "ti",
  APPLICATION_NAME: "TI Dev",
  GOOGLE_CLIENT_ID: "720915024349-8itqk14ncab1aq3eg43mjkjsqisr7gmh.apps.googleusercontent.com",
  GOOGLE_AD_URL: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js",
  ENVIRONMENT: "dev",
  PRELOAD_ASSETS: "true",
  MOBILE_WEB_ONE_LINK: "https://treasureisland.onelink.me/1865833017/9bv8qrtg",
  HELP_LINK: "playticasinohelp.zendesk.com",
  SITE_KEY: "6Le22jcUAAAAAJoEKGduRlplqw7scLoVxpwTjo1n",
  ACCOUNT_EMAIL: "false",
  ACCOUNT_CHANGE_PASSWORD: "false",
  PLAY_AS_GUEST: "true",
  APPLE_STORE_URL: undefined,
  ANDROID_STORE_URL: undefined,
  DSDK_APP_TOKEN: "4247f272-0296-43cf-9234-be32bcda1fde",
  DSDK_HASH_KEY: "9A11232CCB9A599AF0FBF",
  DSDK_SV_URL: "https://a.bluebat.dive.games/TI",
  DSDK_API_URL: "https://api-sandbox.bluebat.dive.games",
  DSDK_DOMAIN: "treasureisland.gtpdev.com",
  EXPERIAN_ENVIRONMENT_KEY: undefined,
  SSO_DEV: undefined,
  NODE_ENV: "production",
  CDN_ROOT: "treasureisland.gtpdev.com",
  APP_ID: undefined,
  VERSION_MAJOR: "2",
  VERSION_MINOR: "12",
  VERSION_PATCH: "0",
  VERSION_BUILD: "1",
  PUBLIC_URL: undefined,
  ZENDESK_URL: "https://playticasinohelp.zendesk.com/hc/en-us/requests/new",
  LINKING_CARD_POPUP_ON_HIT_REWARD_CENTER: "false",
  SHOW_PROPERTY_SELECTOR: "false",
  DYNAMIC_HIGHLIMIT_POPUP: "true",
  DYNAMIC_PROPERTY: "false",
  KEYCHAIN_SERVICE_KEY: undefined,
  SHARED_PREFERENCES_KEY: undefined,
  DIVE_ERROR_HANDLING: "yes" ? "yes" : 'no',
  ENABLE_API_TRACKING: "true",
  GOOGLE_TAG_MANAGER_CONTAINER_ID: "GTM-NXXTX8P",
  APPSFLYER_PATH_PREFIX: undefined,
  LANGUAGE: "en",
  PLAY_AS_GUEST_TYPE: "TEXT",
  ONE_TRUST_DATA_DOMAIN_SCRIPT: undefined,
  FACEBOOK_LOGIN: "true",
  COMING_SOON: "false",
  MOBILE_WEB_SUPPORT: "ios"
};